import UAParser from 'ua-parser-js';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

function getDeviceInfo(callback) {
    let deviceInfo = {};
    let uaParser = new UAParser();
    FingerprintJS.get(
        {
            preprocessor(key, value) {
                if (key === 'userAgent') {
                    return {
                        os: uaParser.getOS().name,
                        browser: uaParser.getBrowser().name,
                    };
                }
                return value;
            },
        },
        (components) => {
            components.forEach((item) => {
                if (item.key !== 'canvas' && item.key !== 'webgl') {
                    deviceInfo[item.key] = item.value;
                }
            });
            deviceInfo.cpuArchitecture = uaParser.getCPU().architecture;
            deviceInfo.device = uaParser.getDevice();
            callback(deviceInfo);
        },
    );
}

export {
    getDeviceInfo,
};

export default getDeviceInfo;
