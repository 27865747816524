<template>
    <login-layout>
        <div class="main">
            <div
                class="side-wrap"
                style="opacity: 0"
            >
                <div class="login-after">
                    <x-addon-code
                        ref="addon_code_after"
                        page="controllers\admin\LoginController"
                        location="after_content"
                    />
                </div>
                <div class="splynx-wellcome">
                    <h3>{{ $t('site', 'Admin portal') }}</h3>
                </div>
            </div>

            <div
                id="page_wrapper"
                class="content-wrap"
            >
                <div id="content">
                    <div
                        class="login-form-wrap"
                        style="opacity: 0"
                    >
                        <div
                            class="splynx-logo"
                            v-html="logo"
                        />
                        <div class="login-form">
                            <div class="login-before">
                                <x-addon-code
                                    ref="addon_code_before"
                                    page="controllers\admin\LoginController"
                                    location="before_content"
                                />
                            </div>
                            <div class="login-heading">
                                <i class="fa fa-sign-in fa-fw" /> {{ $t('site', 'Login for administrators') }}
                            </div>
                            <div class="form-holder">
                                <x-form
                                    id="loginForm"
                                    :check-unsaved-data-before-leave-page="false"
                                    method="POST"
                                    class="form-signin"
                                    role="form"
                                    autocomplete="on"
                                >
                                    <x-input-hidden
                                        id="security_code_1"
                                        v-model="security_code_1"
                                        name="LoginForm[security_code_1]"
                                    />
                                    <x-input-hidden
                                        id="security_code_2"
                                        v-model="security_code_2"
                                        name="LoginForm[security_code_2]"
                                    />

                                    <div class="row">
                                        <x-text-field
                                            id="login"
                                            name="LoginForm[login]"
                                            class="form-control"
                                            placeholder="Login"
                                            autocomplete="username"
                                            :disabled="loading"
                                            autofocus
                                            required
                                        />
                                    </div>

                                    <div class="row">
                                        <input
                                            id="password"
                                            type="password"
                                            name="LoginForm[password]"
                                            class="form-control password"
                                            placeholder="Password"
                                            autocomplete="old-password"
                                            :disabled="loading"
                                            required
                                        >
                                    </div>

                                    <div
                                        id="2fa_key_holder"
                                        class="row"
                                        style="display: none"
                                    >
                                        <x-text-field
                                            id="code"
                                            name="LoginForm[code]"
                                            class="form-control"
                                            placeholder="Code"
                                        />
                                    </div>

                                    <div
                                        id="admin_login_form_trusted_device"
                                        class="row"
                                        style="display: none"
                                    >
                                        <label
                                            for="admin_login_form_trusted_device_checkbox"
                                            class="label-checkbox"
                                        >{{ $t('site', 'Trusted device') }}
                                            <x-input-checkbox
                                                id="admin_login_form_trusted_device_checkbox"
                                                v-model="deviceIsTrusted"
                                                name="LoginForm[trustedDevice]"
                                            />
                                        </label>
                                    </div>

                                    <div id="error" />

                                    <div class="login-footer">
                                        <input
                                            type="submit"
                                            class="btn btn-primary"
                                            :value="$t('site', 'Sign in')"
                                        >
                                        <x-bootstrap-spinner
                                            v-show="loading"
                                            type="primary"
                                            size="md"
                                        />

                                        <router-link
                                            v-if="reset_password"
                                            to="/admin/reset-password"
                                            class="reset-password-link btn btn-cancel"
                                        >
                                            {{ $t('portal', 'Reset password') }}
                                        </router-link>
                                        <a
                                            v-else
                                            href="https://splynx.com/157/how-to-reset-admin-password/"
                                            target="_blank"
                                            class="reset-password-link btn btn-cancel"
                                        >{{ $t('site', 'Forgot your password?') }}</a>
                                    </div>
                                </x-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </login-layout>
</template>

<script>
import LoginLayout from '@/components/layouts/LoginLayout';
import { XAddonCode } from '@/components/base/XAddonCode';
import { XForm } from '@/components/common/XForm';
import { XTextField } from '@/components/common/inputs/XTextField';
import { XInputCheckbox } from '@/components/common/inputs/XInputCheckbox';
import { XInputHidden } from '@/components/common/inputs/XInputHidden';
import { getDeviceInfo } from '@/utils/fingerprint';
import { XBootstrapSpinner } from '@/components/common/XBootstrapSpinner';

/* global md5, base64_encode, csrf */
export default {
    name: 'AdminLogin',
    components: {
        XForm,
        LoginLayout,
        XTextField,
        XInputHidden,
        XInputCheckbox,
        XAddonCode,
        XBootstrapSpinner,
    },
    data() {
        return {
            security_code_1: '',
            security_code_2: '',
            reset_password: {
                type: Boolean,
                default: true,
            },
            deviceInfoToSend: {},
            deviceIsTrusted: false,
            loading: false,
        };
    },
    computed: {
        logo() {
            return spl_config.header;
        },
    },
    beforeMount() {
        $.ajax({
            url: '/admin/login--get-security-codes',
            success: (data) => {
                this.security_code_1 = data.security_code_1;
                this.security_code_2 = data.security_code_2;
                this.reset_password = data.reset_password == true;
            },
        });
    },
    beforeCreate() {
        $.getScript('/js/development/php.min.js');
    },
    mounted() {
        $('#loginForm').submit(this.handleLogin);

        if (window.requestIdleCallback) {
            requestIdleCallback(this.setDeviceInfo);
        } else {
            setTimeout(this.setDeviceInfo, 500);
        }

    // $( document ).ready(function() {
    // $('.dropdown-toggle').dropdown();
    // });
    },
    methods: {
        setDeviceInfo() {
            if (typeof getDeviceInfo === 'function') {
                getDeviceInfo((deviceInfo) => {
                    this.deviceInfoToSend = deviceInfo;
                });
            }
        },
        $_GET(param) {
            let vars = {};
            window.location.href.replace(window.location.hash, '').replace(
                /[?&]+([^=&]+)=?([^&]*)?/gi, // regexp
                (m, key, value) => { // callback
                    vars[key] = value !== undefined ? value : '';
                },
            );

            if (param) {
                return vars[param] ? vars[param] : null;
            }
            return vars;
        },
        password_hash(p1, p2) {
            return md5(base64_encode(md5(p1) + md5(p2)));
        },
        handleLogin() {
            this.loading = true;

            let l = $('#login').val();
            let p = $('#password').val();
            let e1 = $('#security_code_1').val();
            let e2 = $('#security_code_2').val();
            let code = $('#code').val();

            let ee = this.password_hash(p, e2);
            if (code != undefined && code.length > 0) {
                code = this.password_hash(code, e1);
            } else {
                code = '';
            }

            let url = '/admin/login';
            let return_url = this.$_GET('return');
            if (return_url !== null) {
                url += `?return=${return_url}`;
            }

            $.ajax({
                type: 'POST',
                url,
                data: {
                    login: l,
                    security_code_1: e1,
                    security_code_2: ee,
                    code,
                    trustedDevice: this.deviceIsTrusted,
                    deviceInfo: this.deviceInfoToSend,
                },
                dataType: 'json',
                success(data) {
                    if (data.result === 'error') {
                        $('#error').html(data.html);
                    } else if (data.result == 'new_codes') {
                        $('#security_code_1').val(data.security_code_1);
                        $('#security_code_2').val(data.security_code_2);

                        $('#loginForm').submit();
                    } else if (data.result === 'success') {
                        if (data.return) {
                            let { hash } = document.location;
                            window.top.location.href = data.return + (hash || '');
                        } else {
                            window.top.location.href = '/admin/dashboard';
                        }
                    } else if (data.result === 'regenerate_csrf') {
                        $('#login').val('');
                        $('#password').val('');

                        csrf.setCsrfToken('_csrf_token', data.csrf_token);

                        $('#security_code_1').val(data.security_code_1);
                        $('#security_code_2').val(data.security_code_2);

                        $('#error').html(data.html);
                    } else if (data.result === '2fa') {
                        $('#2fa_key_holder').show();
                        if (data.isRemoteSupport !== true) {
                            $('#admin_login_form_trusted_device').show();
                        }

                        $('#code').focus();
                    }
                },
                complete: () => {
                    this.loading = false;
                },
            });

            return false;
        },
    },
};
</script>
