<template>
    <empty-layout>
        <router-view />
    </empty-layout>
</template>

<script>

import EmptyLayout from '@/components/layouts/EmptyLayout';

export default {
    name: 'App',
    components: { EmptyLayout },
};
</script>

<style lang="scss">
    @import '../../fonts/FluentSystemIcons-Regular';
    @import '../../fonts/Inter-Regular';
    @import "~bootstrap/scss/functions";
    @import '../../design/bootstrap/bootstrap-variables'; //for global bootstrap vars
    @import '../../design/mixins';
    @import '../../design/grid/grid';
    @import "../../design/bootstrap/custom-bootstrap.scss";
    @import "../../design/typography/_buttons.scss";
    @import "../../design/typography/animations";
    @import '../../design/typography/icons-wrap';
    @import "../../design/typography/toaster";
    @import '../../design/vars/variables';
</style>
