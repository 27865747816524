import Vue from 'vue';

import $ from 'jquery';
import Popover from 'vue-js-popover';

window.Vue = Vue;
window.$ = $;
window.jQuery = $;
global.$ = $;
global.jQuery = $;

Vue.use(Popover, { tooltip: true });

require('@/../web/js/development/jquery.address.min');
require('@/../web/js/development/jquery.cookie');
require('@/../web/js/development/jquery.noty.packaged.min');
require('@/../web/js/development/jquery.noty.manager');

require('@/../web/js/development/csrf');

require('@/../web/js/development/functions');
