import Vue from 'vue';
import VueRouter from 'vue-router';
import Locale from '@/locale';
import Vuex from 'vuex';
import global_notifications_store from '@/components/base/XGlobalNotification/store';
import Emitter from '@/utils/emmiter';
import XNotificationsPlugin from '@/plugins/XNotificationsPlugin';
import router from './router';
import App from './App';
import notifications from '../admin/store/modules/base/notifications';

Vue.use(VueRouter);
Vue.use(XNotificationsPlugin);

window.splynx_event_bus = new Emitter();

if (typeof window.spl_config === 'undefined') {
    window.spl_config = {
        language: 'en',
        header: {
            logo: '',
        },
    };
}

let locale = new Locale(window.spl_config.language, window.spl_config.listTranslateCategories);

require('./bootstrap');

window.xApp = new Vue({
    router,
    store: new Vuex.Store({
        modules: {
            notifications,
            global_notifications_store,
        },
    }),
    splang: locale.splang,
    render: (h) => h(App),
});
xApp.$mount('#app');

Vue.config.devtools = process.env.NODE_ENV === 'development';
