<template>
    <div class="splynx-wrapper login-page">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'LoginLayout',
};
</script>

<style lang="scss">
    .pswp {
        display: none;
    }

    .splynx-wrapper {
        transition: opacity 0.5s;
    }

    .login-page .splynx-logo {
        padding: 0;
        display: block;
        text-align: center;
        margin-bottom: 20px;
    }

    .login-page .splynx-logo .navbar-brand {
        width: 100%;
        height: auto;
    }

    .login-page .splynx-logo .navbar-brand img {
        max-height: 200px !important;
    }

    .login-page .splynx-logo a img {
        max-height: 200px !important;
        width: auto;
    }

    .login-page .main {
        display: flex;
        flex-direction: row;
    }

    @media (max-width: 1024px) {
        .login-page .main {
            flex-direction: column;
        }
    }

    @media (max-width: 768px) {
        .login-page .main {
            padding-top: 40px;
        }
    }

    .login-page .main .side-wrap {
        width: 35%;
        background-color: #152733;
        border-color: #152733;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 32px;
        margin-bottom: 0;
        flex-flow: wrap;
        animation-name: fadeIn;
        animation-duration: 0.3s;
        animation-delay: 1s;
        animation-fill-mode: both;
        order: 2;
        flex-direction: column;
        text-align: center;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    @media (max-width: 1024px) {
        .login-page .main .side-wrap {
            transform: translateX(0%);
            width: 100%;
            transition: 0s;
            position: static;
            box-shadow: unset;
            height: 100%;
            background-color: #152733;
            border-color: #152733;
            z-index: 20;
            opacity: 1 !important;
            padding: 40px 30px 60px;
            border-radius: 0;
            margin-bottom: 0;
        }
    }

    .login-page .main #page_wrapper.content-wrap {
        min-width: 60%;
        order: 1;
    }

    @media (max-width: 1024px) {
        .login-page .main #page_wrapper.content-wrap {
            width: 100%;
        }
    }

    .login-page .main #page_wrapper.content-wrap #content {
        padding-bottom: 0;
    }

    .login-page .login-form-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;
        animation-name: fadeIn;
        animation-duration: 0.3s;
        animation-delay: 1s;
        animation-fill-mode: both;

        @media (max-width: 1024px) {
            padding: 30px 0;
        }
    }

    .login-page .login-form-wrap .login-form {
        background: #fff;
        border-radius: 4px;
        overflow: hidden;
        margin: 0 auto;
        max-width: 100%;
        width: 500px;
        padding: 40px;
    }

    .login-page .login-form-wrap .login-form .row {
        margin-bottom: 16px;
    }

    @media (max-width: 500px) {
        .login-page .login-form-wrap .login-form {
            max-width: 86%;
            width: 86%;
        }
    }

    .login-page .login-form-wrap .login-heading {
        color: #333;
        text-transform: uppercase;
        width: 100%;
        padding-top: 0;
        padding-bottom: 40px;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
    }

    .login-page .login-form-wrap .form-holder .row {
        margin-bottom: 16px;
    }

    .login-page .input-holder-checkbox {
        display: inline-block;
        vertical-align: middle;
    }

    .login-page .login-form-wrap .label-checkbox {
        margin-bottom: 0;
        margin-right: 10px;

        .form-check {
            min-height: initial;
            margin-top: 5px;
        }
    }

    .login-page .login-form-wrap .login-before {
        clear: both;
        margin-bottom: 20px;
    }

    .login-page .login-form-wrap .login-footer {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-flow: wrap;
        padding-top: 20px;
        margin-left: -8px;
        margin-right: -8px;

        input[type=submit],
        button {
            margin-bottom: 20px;
        }
    }

    .login-page .login-form-wrap .login-error {
        color: #a94442;
        margin-bottom: 20px;
    }

    .login-page .login-form-wrap .login-info {
        color: #616161;
        text-align: center;
        font-size: 16px;
        margin-bottom: 40px;
    }

    @-webkit-keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .login-page .login-after {
        clear: both;
    }

    .login-page .login-after h1, .login-page .login-after h2,
    .login-page .login-after h3, .login-page .login-after h4,
    .login-page .login-after h5, .login-page .login-after h6,
    .login-page .login-after p, .login-page .login-after span {
        color: #fff;
    }

    .login-page .login-after button {
        color: inherit;
        background: #0093FF;
        border-color: #0093FF;
    }

    .splynx-isp .login-page .splynx-wellcome {
        margin: 10px 0;
    }

    .splynx-isp .login-page .splynx-wellcome h3 {
        color: #fff;
        text-transform: uppercase;
        text-align: center;
        font-size: 24px;
    }

    .splynx-isp .login-page .splynx-wellcome .btn-wrap {
        text-align: center;
    }

    .splynx-isp .login-page .splynx-wellcome .btn-wrap .btn {
        width: 200px;
        border-radius: 6px;
        border: 0;
        padding: 6px 28px;
        font-size: 14px;
        text-decoration: none;
        cursor: pointer;
        outline: none;
        transition: all 0.3s ease;
        background-color: #0093FF;
        color: #fff;
        box-shadow: 0 0 0 rgba(80, 182, 255, 0.31);
        margin: 40px auto;
    }

    .noty_message .activity-item {
        padding: 5px;
    }
</style>
