import VueRouter from 'vue-router';
import { flatRoutes } from '@/utils/router/flat-routes';
import { mergeBreadcrumbs } from '@/utils/router/merge-breadcrumbs';
import AdminRoutes from './routes/admin';

const routes = flatRoutes(mergeBreadcrumbs([
    {
        path: '/',
        redirect: (to) => {
            to({ name: 'admin.login' });
        },
    },
    {
        path: '/admin',
        children: AdminRoutes,
    },
]));

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;
