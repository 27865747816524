import AdminLogin from '@/apps/admin-login/views/login/Login.vue';

const ResetPassword = () => import(/* webpackChunkName: "reset_password" */ '@/apps/admin-login/views/reset-password/ResetPassword');
const VerifyCode = () => import(/* webpackChunkName: "reset_password" */ '@/apps/admin-login/views/reset-password/VerifyCode');
const SetNewPassword = () => import(/* webpackChunkName: "reset_password" */ '@/apps/admin-login/views/reset-password/SetNewPassword');

export default [
    {
        name: 'admin.login',
        path: 'login',
        component: AdminLogin,
    },
    {
        path: 'reset-password',
        name: 'admin.reset_password',
        component: ResetPassword,
    },
    {
        path: 'reset-password--verify-check-code',
        name: 'admin.reset_password.verify',
        component: VerifyCode,
    },
    {
        path: 'reset-password--set-new-password',
        name: 'admin.reset_password.set_new_password',
        component: SetNewPassword,
    },
];
